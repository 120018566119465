<template>
  <div class="discount">
    <div class="title">我的折扣活动</div>
    <div class="cell">
      <div class="titles">
        <div>促销列表</div>
        <div>
          <el-button
            @click="handleCreate('add', 0)"
            size="medium"
            type="primary"
            >创建</el-button
          >
        </div>
      </div>
      <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="display: flex; align-items: center; margin-right: 20px">
              <div style="width: 50px">搜索</div>
              <el-input
                size="small"
                style="width: 460px"
                placeholder="请输入"
                v-model="keyword"
                class="input-with-select"
              >
                <el-select
                  style="width: 120px"
                  v-model="select"
                  slot="prepend"
                  placeholder="请选择"
                >
                  <el-option label="活动名称" value="1"></el-option>
                  <el-option label="商品名称" value="2"></el-option>
                  <!-- <el-option label="商品ID" value="3"></el-option> -->
                </el-select>
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <div style="width: 120px">折扣活动时间</div>
              <el-date-picker
                size="small"
                style="width: 460px"
                v-model="time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                prefix-icon="el-icon-date"
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
              >
              </el-date-picker>
            </div>
            <div style="margin-left: 30px">
              <el-button
                size="small"
                style="width: 70px"
                type="primary"
                @click="getList"
                >搜索</el-button
              >
              <el-button
                size="small"
                style="width: 70px"
                @click="handleReset"
                plain
                >重设</el-button
              >
            </div>
          </div>
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="进行中的活动" name="1"></el-tab-pane>
          <el-tab-pane label="接下来的活动" name="2"></el-tab-pane>
          <el-tab-pane label="已过期" name="3"></el-tab-pane>
          <div class="list">
            <div class="list-head">
              <div style="width: 35%">促销名称</div>
              <div style="width: 35%">商品</div>
              <div style="width: 30%">状态</div>
              <div style="width: 20%">活动时间</div>
              <div style="width: 10%">操作</div>
            </div>
            <div v-if="httpsShow">
              <div v-if="listData.list && listData.list.length != 0">
                <div
                  class="list-content"
                  :style="{ border: i == 0 ? 'none' : '' }"
                  v-for="(item, i) in listData.list"
                  :key="item.id"
                >
                  <div style="width: 35%">{{ item.discount_name }}</div>
                  <div style="width: 35%">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="item.goods_image"
                      fit="fit"
                    ></el-image>
                  </div>
                  <div style="width: 30%">
                    <div v-if="item.status_txt == '进行中的活动'" class="btn1">
                      进行中的活动
                    </div>
                    <div v-if="item.status_txt == '接下来的活动'" class="btn2">
                      接下来的活动
                    </div>
                    <div v-if="item.status_txt == '已过期'" class="btn3">
                      已过期
                    </div>
                  </div>
                  <div style="width: 20%">
                    <div>{{ item.start_time | formatTime }}-</div>
                    <div style="margin-top: 4px">
                      {{ item.end_time | formatTime }}
                    </div>
                  </div>
                  <div style="width: 10%">
                    <div
                      class="btnA"
                      v-if="item.status_txt == '已过期'"
                      @click="handletoInfo('info', item.discount_id)"
                    >
                      详情
                    </div>
                    <div
                      class="btnA"
                      @click="handlePush('edit', item)"
                      v-if="
                        item.status_txt == '进行中的活动' ||
                        item.status_txt == '接下来的活动'
                      "
                    >
                      编辑
                    </div>
                    <div
                      class="btnA"
                      @click="handleCreate('copy', item.discount_id)"
                    >
                      复制
                    </div>
                    <div
                      class="btnA"
                      v-if="
                        item.status_txt == '进行中的活动' ||
                        item.status_txt == '已过期'
                      "
                      @click="handletoDada('data', item.discount_id)"
                    >
                      数据
                    </div>
                    <div
                      class="btnA"
                      v-if="item.status_txt == '进行中的活动'"
                      @click="handleDel(1, item)"
                    >
                      结束
                    </div>
                    <div
                      class="btnA"
                      v-if="item.status_txt == '接下来的活动'"
                      @click="handleDel(2, item)"
                    >
                      删除
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                style="text-align: center; color: #b0b0b0; line-height: 100px"
              >
                暂无
              </div>
            </div>
            <div v-else style="text-align: center; padding-top: 20px">
              <img :src="lodingImg" alt="" />
            </div>
          </div>
          <div style="display: flex; justify-content: right; padding: 20px">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="listData.total"
              :page-size="page_size"
              :current-page="page"
              @current-change="change"
            >
            </el-pagination>
          </div>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { discountList, deleteDiscount } from "@/api/marketing.js";
export default {
  data() {
    return {
      activeName: "0",
      select: "1",
      keyword: "",
      time: [],
      listData: "",
      page: 1,
      page_size: 10,
      httpsShow: false,
      lodingImg: require("@/assets/img/app/http.gif"),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList(type) {
      this.httpsShow = false;
      let time = JSON.stringify(this.time);
      time = JSON.parse(time);
      if (this.time.length != 0) {
        time[0] = time[0] / 1000;
        time[1] = time[1] / 1000;
      }

      discountList({
        status: this.activeName,
        active_name: this.select == 1 ? this.keyword : "",
        goods_name: this.select == 2 ? this.keyword : "",
        goods_id: this.select == 3 ? this.keyword : "",
        active_time: time.length != 0 ? time.join("-") : "",
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.httpsShow = true;
        if (res.code == 1) {
          this.listData = res.data;
          if (type) {
            this.listData.list.forEach((item) => {
              item.status_txt = "已过期";
            });
          }
        }
      });
    },
    handleClick() {
      this.page = 1;
      this.getList();
    },
    handleCreate(type, id) {
      sessionStorage.removeItem("KZTYPE");
      sessionStorage.DISCOUNTTYPE = type;
      sessionStorage.DISCOUNTID = id;
      this.$router.options.routes.forEach((item) => {
        if (item.name == "createDiscount") {
          item.children.forEach((val) => {
            if (val.name == "createCoupons") {
              val.meta.bread[3].name = "建立新的折扣活动";
            }
          });
        }
      });
      this.$router.push("createDiscount");
    },
    handleDel(type, data) {
      let _this = this;
      let text = type == 1 ? "结束" : "删除";
      this.$confirm("确定" + text + "该折扣促销?", text + "折扣促销", {
        confirmButtonText: text,
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDiscount({
            id: data.discount_id,
            type: type,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: text + "成功!",
              });
              if (type == 1) {
                this.activeName = "3";
              }
              _this.getList(true);
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    handletoInfo(type, id) {
      sessionStorage.DISCOUNTTYPE = type;
      sessionStorage.DISCOUNTID = id;
      this.$router.push("discountInfo");
    },
    handletoDada(type, id) {
      sessionStorage.DISCOUNTTYPE = type;
      sessionStorage.DISCOUNTID = id;
      this.$router.push("discountData");
    },
    handleReset() {
      this.select = "1";
      this.keyword = "";
      this.time = "";
      this.getList();
    },
    handlePush(type, val) {
      
      sessionStorage.KZTYPE = val.status_txt;
      if (type == "edit" || type == "info") {
        sessionStorage.DISCOUNTTYPE = type;
        sessionStorage.DISCOUNTID = val.discount_id;
        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createDiscount") {
                val.meta.bread[3].name =
                  type == "edit" ? "编辑折扣活动" : "折扣活动详情";
              }
            });
          }
        });

        this.$router.push("createDiscount");
      }
    },
    change(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>
<style lang="less" >
.discount {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  .title {
    font-size: 20px;
  }
  .cell {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tabs {
      margin-top: 30px;
      .el-tabs__nav {
        height: 50px;
      }
      .el-tabs__active-bar {
        height: 4px;
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
      }
      .list {
        border: 1px solid #eee;
        margin-top: 30px;
        border-radius: 4px;
        color: #666;
        font-size: 14px;
        .list-head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          background: #f6f6f6;
        }
        .list-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 30px 0;
          margin: 0 20px;
          font-size: 12px;
          border-top: 1px solid #eee;
        }
      }
    }
  }
  .btn1 {
    background: #eaf9ef;
    color: #55cc77;
    display: initial;
    padding: 2px 4px;
    border-radius: 2px;
  }
  .btn2 {
    background: #fff1f0;
    color: #ee4d2d;
    display: initial;
    padding: 2px 4px;
    border-radius: 2px;
  }
  .btn3 {
    background: #eeeeee;
    color: #666;
    display: initial;
    padding: 2px 4px;
    border-radius: 2px;
  }
  .btnA {
    color: #367ddf;
    margin-bottom: 6px;
    cursor: pointer;
  }
}
</style>